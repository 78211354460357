import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public env = environment;
  public menus = [
    {
      title: 'Accueil',
      url: '/home',
      droit:'',
      icon: 'home'
    },
    {
      title: 'Plan',
      url: '/admin-plan',
      droit:'cle',
      icon: 'map'
    },
    {
      title: 'Gestion des clés',
      url: '/gestion_cle',
      droit:'cle',
      icon: 'key'
    },
    {
      title: 'Gestion du matériel',
      url: '/admin-materiel',
      droit:'cle',
      icon: 'cube'
    },
    {
      title: 'Gestion du magasin',
      url: '/admin-magasin',
      droit:'magasin',
      icon: 'cart'
    },
    {
      title: 'Check inventaire',
      url: '/check-inventaire',
      droit:'admin',
      icon: 'barcode'
    },
    {
      title: 'Admin',
      url: '/admin-menu',
      droit:'admin',
      icon: 'settings'
    },
    {
      title: 'Scan',
      url: '/scan',
      droit:'admin',
      icon: 'scan-circle'
    },
    {
      title: 'Déconnexion',
      url: '/logout',
      droit:'logged',
      icon: 'close-circle'
    },
  ];
  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.show();
      // this.splashScreen.hide();
    });
  }
  getUserEmail(){
    let d=this.getUserInfos();
    if(d==null) return '';
    else if(!d.hasOwnProperty('email'))
    {
      let d2:any=d;
      if(d2.indexOf('@')>1) return d2;
      else return '';
    } 
    else return d['email'];
  }
  getUserInfos(){
    let d=window.localStorage.getItem('acajou_user');
    if(d!=null && d!=''){
      d=JSON.parse(d);
      return d;
    }
    else return {id:0,pseudo:'',email:''};
  }
  checkDroits(p)
  {
    let d=window.localStorage.getItem('droit_'+p['droit']);
    // console.log('checkDroits',p,d);
    // console.log('checkDroits',p,d2,isConnecte);
    if(p['droit']=='logged'){ 
      let d2=window.localStorage.getItem('acajou_cookie');
      if(d2?.length>5) return true;
      else return false;
    }
    if(d=='OK' || p['droit']=='') return true;
    return false;
  }
}
