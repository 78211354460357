import { Platform, LoadingController, MenuController, ModalController, AlertController, ActionSheetController  } from '@ionic/angular';
import { Injectable, Output, EventEmitter } from '@angular/core';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

//modal
import { ModalSallePage } from './modal-salle/modal-salle.page';


//plugins
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpParams } from "@angular/common/http";
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

import * as moment from 'moment';
import 'moment/locale/fr';  // without this line it didn't work


@Injectable({
  providedIn: 'root'
})
export class AcajouService {
  //-------------------------------------------------
  loading : any;
  boolLoadingEnCours : boolean = false;
  boolAdmin : boolean = false;
  donnees:any;
  url_serveur:string='';
  googleCloudVisionAPIKey:string="AIzaSyAEEWPFYzpQ6fzK5fvirZKUopteCrh2_Pw";
  //-------------------------------------------------
  constructor(private platform: Platform,
    public loadingCtrl : LoadingController,
    public http: HttpClient,
    private router: Router,
    private camera: Camera,
    private mediaCapture: MediaCapture,
    private file: File,
    private menu: MenuController,
    private barcodeScanner: BarcodeScanner,
    private modalController: ModalController,
    private alertController: AlertController,
    private speechRecognition: SpeechRecognition,
    private oneSignal: OneSignal,
    public actionSheetController: ActionSheetController,
    private http2: HTTP) 
  {
    this.url_serveur='https://acajou2.com/app/api.php';
    // this.url_serveur='http://10.206.4.59/site/app/api.php';
  }
  //-------------------------------------------------
  getUrlApi(chx)
  {
    return this.url_serveur+'?chx='+chx;
  }
  //-------------------------------------------------
  getBEHttp(url,data={},arg={}){
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      //console.log('getbehttpURL',url);
      this.http2.setDataSerializer('urlencoded');
      this.http2.get(url,data,arg).then((resp)=>{
        //console.log('getbehttp',resp,url,data,arg);
        if(resp!=null && resp.hasOwnProperty('data')) resp=JSON.parse(resp['data']);
        if(resp!=null && resp.hasOwnProperty('namespace') && resp['namespace']=='beapp972') resp=null;
        //console.log('getbehttp2',resp);
        resolve(resp);
      },(err)=>{
        // console.log('erreur getbehttp',url);
        this.http.get(url,{headers:headers}).subscribe((resp)=>{
          if(resp!=null && resp.hasOwnProperty('data')) resp=JSON.parse(resp['data']);
          //console.log('getbehttp2',resp);
          resolve(resp);
        },(err)=>{
          resolve(null);
        });
      });
    });
  }
//-------------------------------------------------
   async presentLoading(msg,duree) {
    this.loading = await this.loadingCtrl.create({
      message: msg,
      duration: duree
    });
    return await this.loading.present();
  }
  //-------------------------------------------------
  posterDonnees(url,data){
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      this.http.post(url, data,{headers:headers}).subscribe((resp) => {
        resolve(resp);
      },(err)=>{
        reject(err);
        console.log(err, 'error reached');
      });
    });
  }
    //-------------------------------------------------
  showLoading(txt='...chargement...',timeLimit=60000){
    if(!this.boolLoadingEnCours)
    {
      this.boolLoadingEnCours=true;
      this.presentLoading(txt,timeLimit);
    }
  }
  //-------------------------------------------------
  hideLoading(){
    setTimeout(()=>{
      if(this.loading==undefined) { this.boolLoadingEnCours=false; return; }
      this.loading.dismiss().then(()=>{
        this.boolLoadingEnCours=false;
        console.log('hideloading ok');
      },(err)=>{
        this.boolLoadingEnCours=false;
        console.log('hideloading error');
      });
    },500);
  }
  //-------------------------------------------------
  chargerDonnees(chx){
    return new Promise((resolve,reject) => {
    let url=this.getUrlApi(chx);
    this.getBEHttp(url).then((result:any)=>{
      console.log('Ok', result);
      window.localStorage.setItem('acajou_'+chx, JSON.stringify (result));
      resolve(null);
    }, (err)=>{
      console.log('Erreur', err);
      reject();
    }); })
  }
  /////////////////////////////////////////////
  rafraichirDonnees(forcerRefresh=true)
  {
    return new Promise((resolve,reject) => {
     if(forcerRefresh==false){
        this.donnees=JSON.parse(window.localStorage.getItem('acajou_alldata'));
        this.donnees['salle'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        this.donnees['zone'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        this.donnees['materiel'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        this.donnees['probleme'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        this.donnees['type_probleme'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        this.donnees['type_materiel'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
        setTimeout(()=>{
          resolve(null);
        },500);
     }
     else
     {
        this.chargerDonnees('alldata').then(()=>{
          this.donnees=JSON.parse(window.localStorage.getItem('acajou_alldata'));
          this.donnees['salle'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
          this.donnees['zone'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
          this.donnees['materiel'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
          this.donnees['probleme'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
          this.donnees['type_probleme'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});
          this.donnees['type_materiel'].sort((a, b)=>{ if (a['nom'] > b['nom']) { return 1; } if (a['nom'] < b['nom']) { return -1; } return 0;});

          resolve(null);
        });
     }
    }); 
  }
  /////////////////////////////////////////////
  pagePrecedente(){
    // this.router.
    //test a voir
  }
  /////////////////////////////////////////////
  goPage(lien,param=null,boolRetour=false)
  {
    //test a voir
    if(lien=='/') {
      this.router.navigateByUrl('/',{skipLocationChange: true});
      // this.router.navigate(['/'], { queryParams: { returnUrl: '/' }});
      //this.router.navigate(['/'], { replaceUrl: true });
    }
    else if(param!=null) this.router.navigate([lien,param]);
    else this.router.navigate([lien]);
  }
  /////////////////////////////////////////////
  checkLogin(){
    return new Promise((resolve)=>{
      let c=window.localStorage.getItem('acajou_cookie');
      if(c!=null && c!=undefined && c.length>10) resolve(true);
      else resolve(false);
    });
  }
  /////////////////////////////////////////////
  login(email,pass){
    return new Promise((resolve,reject)=>{
      let url=this.getUrlApi('login');
      this.posterDonnees(url,{email:email,pass:pass}).then((result:any)=>{
        console.log('Ok', result);
        console.log('pass', pass);
        console.log('email', email);
        if(result!=null && result!=undefined && result.hasOwnProperty('cookie'))
        {
          window.localStorage.setItem('acajou_user', JSON.stringify(result));
          window.localStorage.setItem('acajou_cookie', result['cookie']);
          window.localStorage.setItem('droit_admin', result['droit_admin']);
          window.localStorage.setItem('droit_cle', result['droit_cle']);
          window.localStorage.setItem('droit_magasin', result['droit_magasin']);
          window.localStorage.setItem('droit_lecture_seule', '0');
          resolve(true);
        }
        else if((pass=='' || pass==undefined || pass==null) && (email.indexOf('@ac-martinique.fr')>1 || email.indexOf('@collectivitedemartinique.mq')>1))
        {
          console.log('ok mode prof ou personnel1');
          window.localStorage.setItem('acajou_user', JSON.stringify(email));
          window.localStorage.setItem('acajou_cookie', 'academique-'+email);
          window.localStorage.setItem('droit_admin', '0');
          window.localStorage.setItem('droit_cle', '0');
          window.localStorage.setItem('droit_magasin', '0');
          window.localStorage.setItem('droit_lecture_seule', '1');
          resolve(true);
        }
        else
        {
          window.localStorage.setItem('acajou_user', null);
          window.localStorage.setItem('acajou_cookie', null);
          window.localStorage.setItem('droit_admin', null);
          window.localStorage.setItem('droit_cle', null);
          window.localStorage.setItem('droit_lecture_seule', null);
          resolve(false);
        }
        resolve(false);
        //'droit_admin'
        //''
      }, (err)=>{
        console.log('Erreur', err);
        if((pass=='' || pass==undefined || pass==null) && (email.indexOf('@ac-martinique.fr')>1 || email.indexOf('@collectivitedemartinique.mq')>1))
        {
          console.log('ok mode prof ou personnel2');
          window.localStorage.setItem('acajou_user', JSON.stringify(email));
          window.localStorage.setItem('acajou_cookie', 'academique-'+email);
          window.localStorage.setItem('droit_admin', '0');
          window.localStorage.setItem('droit_cle', '0');
          window.localStorage.setItem('droit_magasin', '0');
          window.localStorage.setItem('droit_lecture_seule', '1');
          resolve(true);
        }
        else reject();
      });
    });
  }
  /////////////////////////////////////////////
  logout()
  {
    window.localStorage.setItem('acajou_user', null);
    window.localStorage.setItem('acajou_cookie', null);
    window.localStorage.setItem('droit_admin', null);
    window.localStorage.setItem('droit_cle', null);
    window.localStorage.setItem('droit_lecture_seule', null);
    window.localStorage.setItem('droit_magasin', null);
  }
  /////////////////////////////////////////////
  msgAlerte(msg)
  {
    this.showLoading(msg,5000);
  }
  /////////////////////////////////////////////
  toggleMenu(){
    this.menu.toggle();
  }
  /////////////////////////////////////////////
  scannerCode(){
    return new Promise((resolve,reject)=>{
      // console.log('scannerCode000001');
      this.QRScan().then((r:string)=>{
        // console.log('scannerCode000002',r);
        if(r=='') reject('');
        else resolve(r);
      });
    });
  }
  /////////////////////////////////////////////
  capturePhoto(){
    return new Promise((resolve)=>{
      let options: CaptureImageOptions = { limit: 1 };
      this.mediaCapture.captureImage(options)
        .then(
          (data: MediaFile[]) => {
            console.log('MediaFileOKs',data);
            /////////////////////////////////////////
            this.file.readAsDataURL(null,data[0].name).then((dt)=>{
              console.log('MediaFileOKsysssss',dt);
              resolve(dt);
            },(err)=>{
              console.log('MediaFileERRR',err);
            });
// fullPath: "file:///storage/emulated/0/Pictures/1625228064013.jpg"
// localURL: "cdvfile://localhost/sdcard/Pictures/1625228064013.jpg"
// name: "1625228064013.jpg"
// size: 2004180
// type: "image/jpeg"
            // resolve(data);
          },
          (err: CaptureError) => {
            resolve(null);
            console.log('MediaFileERR',err);
        });
    //   const options: CameraOptions = {
    //     quality: 100,
    //     destinationType: this.camera.DestinationType.FILE_URI,
    //     encodingType: this.camera.EncodingType.JPEG,
    //     mediaType: this.camera.MediaType.PICTURE
    //   }
    //   this.camera.getPicture(options).then((imageData) => {
    //    // imageData is either a base64 encoded string or a file URI
    //    // If it's base64 (DATA_URL):
    //    let base64Image = 'data:image/jpeg;base64,' + imageData;
    //    resolve(base64Image);
    //   }, (err) => {
    //    resolve(null);
    //    // Handle error
    //   });
    });
  }
  /////////////////////////////////////////////
  //////////////////////////////////
  async scanManuel()
  {
    const alert = await this.alertController.create({
      header: 'Entrez le numéro de série',
      inputs: [
        {
          name: 'num',
          type: 'text',
          placeholder: 'Numéro de série'
        },
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Scanner',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
    return alert.onDidDismiss();
  }
  /////////////////////////////////////////////
  QRScan(cameraPref=''){
    return new Promise((resolve)=>{

      this.barcodeScanner.scan().then(barcodeData => {
// console.log('barcodeScanner',barcodeData);
       resolve(barcodeData.text);
      }).catch(err => {
console.log('barcodeScannerERR',err);
          this.scanManuel().then((r:any)=>{
            // console.log('barcodeScannerERR22',r);
            if(
              r!=null && r!=undefined && r.hasOwnProperty('data')
              && r['data']!=null && r['data']!=undefined && r['data'].hasOwnProperty('values')
              && r['data']['values']!=null && r['data']['values']!=undefined && r['data']['values'].hasOwnProperty('num')
            )
            resolve(r['data']['values']['num']);
            // console.log('barcodeScannerERR2222222',r['data']['values']['num']);
          },(err2)=>{
            // console.log('barcodeScannerERR33',err2);
            resolve('');
          });


      });
      /*

      console.log('qrscanv1.3',cameraPref);
      let codeReader = new BrowserQRCodeReader();    
      let element = document.createElement("div");
      element.id='divVideoElement';
      element.innerHTML='<video id="videoElement"></video>';
      //////////
      //bouton annuler
      let element2 = document.createElement("div");
      element2.id='btnannulerqrscan';
      element2.onclick=()=>{
        console.log('btnannulerqrscanCLICK');
        codeReader.reset();
        document.getElementById('divVideoElement').remove();
        resolve('');
      };
      element.appendChild(element2);
      //////////
      //bouton switch camera
      let element3 = document.createElement("div");
      element3.id='btnswitchqrscan';
      element3.onclick=()=>{
        document.getElementById('divlistecamqrscan').style.display='block';
      };
      element.appendChild(element3);
      //////////
      document.body.appendChild(element);
      let dve=document.getElementById('divVideoElement');

      let btnSwitchCam;
      if(cameraPref=='')
      {
        cameraPref=window.localStorage.getItem('camprefqrscan');
      }
      else
      {
        window.localStorage.setItem('camprefqrscan',cameraPref);
      }
      //div switch camera
      let element2b = document.createElement("div"); element2b.id='divlistecamqrscan'; element.appendChild(element2b);
      codeReader.listVideoInputDevices().then(videoInputDevices => {
        videoInputDevices.forEach((device) =>{
          btnSwitchCam = document.createElement("div");
          btnSwitchCam.className = 'btnchangercamqrscan';
          btnSwitchCam.innerHTML = device.label;
          btnSwitchCam.onclick=()=>{
            console.log('btnSwitchCamCLICK',device);
            codeReader.reset();
            if(dve!=null) dve.remove();
            this.showLoading();
            setTimeout(()=>{
              this.hideLoading();
              this.QRScan(device.deviceId).then((rr:string)=>{
                resolve(rr);
              });
            },1000);
          };
          element2b.appendChild(btnSwitchCam);
          // dve.appendChild(btnSwitchCam);

          if(cameraPref=='' || cameraPref==device.deviceId)
          {


            // setTimeout(()=>{
console.log('TEST006',device);
            cameraPref=device.deviceId;
console.log('device.deviceId',device.deviceId);
            // codeReader.decodeOnceFromVideoDevice(device.deviceId, 'videoElement').then((result:any)=>{
            codeReader.decodeFromVideoDevice(device.deviceId, 'videoElement',(result, err) => {
              if (result) {
                console.log('decodeFromVideoDeviceRRR01',result);
              }
              else {
                console.error('decodeFromVideoDeviceERR001',err);
              }
            });




          }
        });
        dve.appendChild(element2b);
        if(cameraPref=='')
        {
          console.log('cameraPrefVIDE');
          if(dve!=null) dve.remove();
          resolve('');
        }
      }).catch((err) => {
        console.log('cameraPrefVIDE-ERR0-fallback');
        /////////////////////////////////
        /////////////////////////////////
          btnSwitchCam = document.createElement("div");
          btnSwitchCam.className = 'btnchangercamqrscan';
          btnSwitchCam.innerHTML = 'camera';
          btnSwitchCam.onclick=()=>{
            console.log('btnSwitchCamCLICK fallback');
            codeReader.reset();
            if(dve!=null) dve.remove();
            this.showLoading();
            setTimeout(()=>{
              this.hideLoading();
              this.QRScan(undefined).then((rr:string)=>{
                resolve(rr);
              });
            },1000);
          };
          element2b.appendChild(btnSwitchCam);
          // dve.appendChild(btnSwitchCam);

          if(cameraPref=='' || cameraPref==undefined)
          {
            cameraPref=undefined;
            codeReader.decodeOnceFromVideoDevice(undefined, 'videoElement').then((result:any)=>{
            console.log('decodeOnceFromVideoDevice-OK');
              codeReader.reset();
              if(dve!=null) dve.remove();
              resolve(result.text);
            }).catch((err) => {
              console.log('decodeOnceFromVideoDevice-ERR',err);
              if(err.toString().indexOf('Video stream has ended before')<2)
              {
                codeReader.reset();
                if(dve!=null) dve.remove();
                console.error(err);
                resolve('');
              }
            });
          }
        dve.appendChild(element2b);
        if(cameraPref=='')
        {
          console.log('cameraPrefVIDE');
          if(dve!=null) dve.remove();
          resolve('');
        }
        /////////////////////////////////
        /////////////////////////////////
        // console.log('cameraPrefVIDE-ERR');
        // console.error(err);
        // if(dve!=null) dve.remove();
        // resolve('');
      });
      */
    });
  }
  /////////////////////////////////////////////
  async presentModalSalle(callback) {
    const modal = await this.modalController.create({
      component: ModalSallePage,
      cssClass: 'my-custom-class',
      componentProps: { data: this.donnees },
      swipeToClose: false,
      presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
    });
    modal.onDidDismiss().then((data) => {
      if(data!=null && data!=undefined && data.hasOwnProperty('data')) data=data['data'];
      console.log('modaldismisstest333',data);
      callback(data);
    //     const user = data['data']; // Here's your selected user!
    });
    return await modal.present();
  }
  /////////////////////////////////////////////
  stop_reconnaissance_vocale()
  {
    this.speechRecognition.stopListening();
  }
  get_reconnaissance_vocale()
  {
    return new Promise((resolve) => {
      let options={language:'fr-FR'};
      this.speechRecognition.hasPermission().then((hasPermission: boolean)=>{
        if(hasPermission)
        {
          resolve(this.speechRecognition.startListening(options));
          // .subscribe(
          //   (matches: string[]) =>{
          //     console.log(matches)
          //   },
          //   (onerror) => {
          //     console.log('error:', onerror)
          //   }
          // )
        }
        else
        {
          this.speechRecognition.requestPermission().then(
            ()=>{
              this.get_reconnaissance_vocale();
            },
            ()=>{
              resolve(null);
              console.log('Denied')
            }
          )
        }
      });
    });
  }
  /////////////////////////////////////////////
  nb_jours(dt)
  {
    var eventdate = moment(dt);
    var todaysdate = moment();
    return todaysdate.diff(eventdate, 'days');
  }
  /////////////////////////////////////////////
  date_ago(dt)
  {
    moment.locale('fr');
    return moment(dt, "YYYY-MM-DD").add(14, 'hours').fromNow();
  }
  /////////////////////////////////////////////
  date_now(f=null)
  {
    moment.locale('fr');
    if(f==null) return moment();
    else return moment().format(f);
  }
  /////////////////////////////////////////////
  init()
  {
    this.oneSignal.startInit('08156ef9-2ab0-4057-ae23-952ceb76fb10', '85161254463');
    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    // this.oneSignal.handleNotificationReceived().subscribe((data:navigateByUrl) => {
    this.oneSignal.handleNotificationReceived().subscribe((data:any) => {
     // do something when notification is received
     console.log('datanotif',data);
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });

    this.oneSignal.endInit();

    setTimeout(()=>{
      this.envoyerNotifPush('salut c un test');
    },10000);
  }
  /////////////////////////////////////////////
  setUserExternalId(id)
  {
      console.log('testExt0');
    return new Promise((resolve) => {
      console.log('testExt1');
      this.oneSignal.setExternalUserId(id);/*.then((r:any)=>{
        console.log('rrr',r);
      });*/
      console.log('testExt2');
      resolve(null);
    });
  }
  /////////////////////////////////////////////
  envoiNotificationUser(id,msg)
  {
      console.log('testNotif0');
    return new Promise((resolve) => {
   /*   console.log('testNotif1');

      this.oneSignal.postNotification(
        {"en": msg},
        {},
        null,
        {include_player_ids:[id]}
      );

      console.log('testNotif1b');
      this.oneSignal.postNotification({
        contents:{"en": msg},
        headings:{"en": msg},
        include_player_ids:[id],
        // channel_for_external_user_ids:"push",
        // include_external_user_ids:[id],
      }).then((rr:any)=>{
      console.log('testNotif2');
        console.log('successsss',rr);
        resolve(rr);
      },(err)=>{
      console.log('testNotif3');
        console.log('errrrrr',err);
        resolve(null);
      });
    */
    });
  }
  /////////////////////////////////////////////
  envoyerNotifPush(txt)
  {
    let url=this.getUrlApi('envoi_notif_push');
    let dt={ txt:txt };
    this.posterDonnees(url,dt).then((resultat:any)=>{
      //...
    },(err)=>{
    });
  }
  /////////////////////////////////////////////
  getTextePhoto()
  {
    return new Promise((resolve) => {
      this.camera.getPicture({
        quality : 75,
        destinationType : this.camera.DestinationType.DATA_URL,
        sourceType : this.camera.PictureSourceType.CAMERA,
        allowEdit : false,
        encodingType: this.camera.EncodingType.JPEG,
        targetWidth: 250,
        targetHeight: 250,
        saveToPhotoAlbum: false
      }).then(imageData => {
        // console.log('imageData',imageData);
        const body = {"requests": [{
          "image": { "content": imageData },
          "features": [{
            "type": "DOCUMENT_TEXT_DETECTION",
            // "type": "LANDMARK_DETECTION",
            // "type": "FACE_DETECTION",
            // "type": "OBJECT_LOCALIZATION",
            // "type": "LOGO_DETECTION",
            // "type": "LABEL_DETECTION",
            // "type": "SAFE_SEARCH_DETECTION",
            // "type": "IMAGE_PROPERTIES",
            // "type": "CROP_HINTS",
            // "type": "WEB_DETECTION",
            "maxResults":1}]
        }]};
        let url='https://vision.googleapis.com/v1/images:annotate?key=' + this.googleCloudVisionAPIKey;
        this.http.post(url, body).subscribe((result) => {
          console.log('resultphoto',result);
          let tmp=[];
          for(let i in result['responses'][0]['textAnnotations'])
          {
            tmp.push(result['responses'][0]['textAnnotations'][i]['description'])
          }
          this.presentActionSheet(tmp).then((r:any)=>{
            resolve(r);
          });
        }, error => {
          console.log('errorerrorerror773');
          resolve(null);
          //...
        });
      }, error => {
          console.log('errorerrorerror774');
          resolve(null);
        //...
      });
    });
  }
  /////////////////////////////////////////////
  async presentActionSheet(tab)
  {
    let btn=[{ text: 'Annuler', role: 'destructive', icon: 'close', id: 'delete-button', data: '', handler: () => { console.log('Delete clicked'); }}];
    for(let i in tab)
    {
      btn.push({
        text: tab[i],
        icon: 'play',
        role: 'selected',
        id: 'btn'+i,
        data: tab[i],
        handler: () => {
          console.log('tab',tab[i]);
        }
      });
    }
    const actionSheet = await this.actionSheetController.create({
      header: 'Textes détectés',
      cssClass: 'my-custom-class',
      buttons: btn
    });
    await actionSheet.present();

    return actionSheet.onDidDismiss();
    // console.log('onDidDismiss resolved with role and data', role, data);
  }
  /////////////////////////////////////////////
}