import { Component, OnInit, Input } from '@angular/core';
// import { AcajouService } from '../services';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-salle',
  templateUrl: './modal-salle.page.html',
  styleUrls: ['./modal-salle.page.scss'],
})
export class ModalSallePage implements OnInit {
  etape:string='liste';
  id_zone:any=0;
  id_salle:any=0;
  zone:any=[];
  salle:any=[];
  materiel:any=[];
  tab_details:any=[];
  nom_salle:string='';
  @Input("data") data;

  constructor(public alertController: AlertController,private modalController: ModalController, private navParams: NavParams, public route: ActivatedRoute) { }
  //////////////////////////
  ngOnInit() {
    console.log('test1',this.data);
    // this.id = this.route.snapshot.paramMap.get('id');
    this.data=this.navParams.get('data');
    console.log('test2',this.data);
  	this.actualiser();
  }
  //////////////////////////
  actualiser(){
    console.log('test3',this.data);
		this.salle=this.data['salle'];
    this.zone=this.data['zone'];
    this.materiel=this.data['materiel'];
    for(let i in this.zone)
    {
      this.zone[i]['nb_salles']=this.getNbSalleZone(this.zone[i]);
    }
    for(let i in this.salle)
    {
      this.salle[i]['nb_materiel']=this.getNbMaterielSalle(this.salle[i]);
    }
  }
  //////////////////////////
  getNbMaterielSalle(s){
    let nb=0;
    for(let m of this.materiel)
    {
      if(m['id_salle']==s['id']) nb++;
    }
    return nb;
  }
  //////////////////////////
  getNbSalleZone(s){
    let nb=0;
    for(let m of this.salle)
    {
      if(m['id_zone']==s['id']) nb++;
    }
    return nb;
  }
  //////////////////////////
  afficherDetailsMateriel(s){
    /*
    let tab=[];
    this.nom_salle=s['nom'];
    for(let m of this.materiel)
    {
      if(m['id_salle']==s['id'])
      {
        if(!tab.hasOwnProperty('c'+m['id_type_materiel']))
        {
          tab['c'+m['id_type_materiel']]=0;
        }
        tab['c'+m['id_type_materiel']]++;
      }
    }
    this.tab_details=[];
    for(let j in this.data['type_materiel'])
    {
      let n='c'+this.data['type_materiel'][j]['id'];
      let n2=this.data['type_materiel'][j]['nom'];
      for(let m in tab)
      {
        if(m==n)
        {
          this.tab_details.push({nom:n2,nb:tab[m]});
        }
      }
    }
    // console.log('tab',tab);
    // console.log('this.tab_details',this.tab_details);
    // return nb; 
    */
  }
  //////////////////////////
  fermer(d){
    this.modalController.dismiss(d);
  }
  //////////////////////////
  choixZone(z){
    this.id_zone=z.id;
  }
  //////////////////////////

}
