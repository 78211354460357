import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { PopoverMenuComponent } from './popover-menu/popover-menu.component';

//a ajouter
import { AcajouService } from './services';
import { ModalSallePageModule } from './modal-salle/modal-salle.module';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
// import { ZXingScannerComponent } from '@zxing/ngx-scanner';
// import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";


@NgModule({
  declarations: [AppComponent, PopoverMenuComponent],
  entryComponents: [PopoverMenuComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,  //a ajouter
    ZXingScannerModule,
    ModalSallePageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StatusBar,
    // SplashScreen,
    BarcodeScanner,
    Camera,
    File,
    HTTP,//a ajouter
    AcajouService,//a ajouter
    MediaCapture,//a ajouter
    SpeechRecognition,
    OneSignal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
