import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'logout', loadChildren: () => import('./logout/logout.module').then(m => m.LogoutPageModule) },
  { path: 'loginBackground', loadChildren: () => import('./login-background/login-background.module').then(m => m.LoginBackgroundPageModule) },
  {
    path: 'admin-zone',
    loadChildren: () => import('./admin-zone/admin-zone.module').then( m => m.AdminZonePageModule)
  },
  {
    path: 'admin-salle',
    loadChildren: () => import('./admin-salle/admin-salle.module').then( m => m.AdminSallePageModule)
  },
  {
    path: 'admin-salle/:id',
    loadChildren: () => import('./admin-salle/admin-salle.module').then( m => m.AdminSallePageModule)
  },
  {
    path: 'admin-type_probleme',
    loadChildren: () => import('./admin-type_probleme/admin-type_probleme.module').then( m => m.AdminType_problemePageModule)
  },
  {
    path: 'admin-probleme',
    loadChildren: () => import('./admin-probleme/admin-probleme.module').then( m => m.AdminProblemePageModule)
  },
  {
    path: 'admin-type_materiel',
    loadChildren: () => import('./admin-type_materiel/admin-type_materiel.module').then( m => m.AdminType_materielPageModule)
  },
  {
    path: 'admin-materiel',
    loadChildren: () => import('./admin-materiel/admin-materiel.module').then( m => m.AdminMaterielPageModule)
  },
  {
    path: 'admin-magasin',
    loadChildren: () => import('./admin-magasin/admin-magasin.module').then( m => m.AdminMagasinPageModule)
  },
  {
    path: 'admin-materiel/:id',
    loadChildren: () => import('./admin-materiel/admin-materiel.module').then( m => m.AdminMaterielPageModule)
  },
  {
    path: 'admin-materiel_champs',
    loadChildren: () => import('./admin-materiel_champs/admin-materiel_champs.module').then( m => m.AdminMateriel_champsPageModule)
  },
  {
    path: 'admin-menu',
    loadChildren: () => import('./admin-menu/admin-menu.module').then( m => m.AdminMenuPageModule)
  },
  {
    path: 'admin-intervention',
    loadChildren: () => import('./admin-intervention/admin-intervention.module').then( m => m.AdminInterventionPageModule)
  },
  {
    path: 'admin-intervention/:id',
    loadChildren: () => import('./admin-intervention/admin-intervention.module').then( m => m.AdminInterventionPageModule)
  },
  {
    path: 'admin-plan',
    loadChildren: () => import('./admin-plan/admin-plan.module').then( m => m.AdminPlanPageModule)
  },
  {
    path: 'demande-intervention',
    loadChildren: () => import('./demande-intervention/demande-intervention.module').then( m => m.DemandeInterventionPageModule)
  },
  {
    path: 'gestion_cle',
    loadChildren: () => import('./gestion_cle/gestion_cle.module').then( m => m.GestionClePageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'check-inventaire',
    loadChildren: () => import('./check-inventaire/check-inventaire.module').then( m => m.CheckInventairePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
